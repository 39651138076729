<template>
  <li role="presentation">
    <label class="dropdown-item d-flex align-items-center" role="menuitem">
      <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50 border rounded-circle"
      /><span style="font-size: 1rem;">Dodaj awatar</span>
      <input class="d-none" type="file" @change="handleFileUpload($event)" accept="image/png, image/bmp, image/jpeg">
    </label>
  </li>
</template>

<script>
export default {
  data() {
    return {
      file: null,
    }
  },
  methods: {
    handleFileUpload(event){
      this.file = event.target.files[0];
      if (this.file) {
        this.update();
      }
    },
    update() {
      let formData = new FormData();
      formData.append('avatar', this.file);
      this.$http.post(`${this.$store.state.apiDomain}/api/me/avatar`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            let userData = JSON.parse(localStorage.getItem('userData'));
            userData.user.image = this.$store.state.apiDomain + response.data.image
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$router.go();
      })
    },
  },
}
</script>
