<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />
      <cart-dropdown v-if="isWebview" />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData ? userData.user.name : '' }}
            </p>
            <span class="user-status">Klient</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData && userData.user.image ? userData.user.image : require('@/assets/images/avatars/chemmaster-user-avatar.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <UpdateAvatar/>

        <b-dropdown-item
          to="/ustawienia"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Moje ustawienia</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          to="/powiadomienia"
        >
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Powiadomienia</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Wyloguj</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import CartDropdown from '@/layouts/components/CartDropdown'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown'
import NotificationDropdown from '@/layouts/components/NotificationDropdown.vue'
import store from '@/store'
import Vue from "vue";
import UpdateAvatar from './UpdateAvatar';

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UpdateAvatar
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: {},
      isWebview: Vue.prototype.$workbox !== null,
    }
  },
  created() {
    this.$root.$refs.Navbar = this;
    this.updateUserData()
  },
  methods: {
    logout() {
      useJwt.logout()
      this.$router.push({ name: 'login' })
    },
    updateUserData(){
      this.userData = JSON.parse(localStorage.getItem('userData'))
    },
  },
}
</script>
